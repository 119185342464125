.modalMain {
  // padding-left: 30px;
  // padding-right: 30px;
  text-align: center;
  color: #575757;
  width: 60%;
  h2 {
    margin-bottom: 15px;
  }
  form {
    display: flex;
    flex-direction: column;

    input {
      outline: none;
      padding-left: 30px;
      margin-top: 15px;
      width: 100%;
      height: 40px;
      border: 1px solid rgba(19, 19, 19, 0.2);
      box-sizing: border-box;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    }

    // a {
    //   text-align: start;
    //   margin-top: 10px;
    // }

    // .button {
    //   background-color: #fff;
    //   border: none;
    //   text-decoration: underline;
    //   cursor: pointer;
    // }
    .signButton {
      cursor: pointer;
      background: #252525;
      opacity: 0.8;
      border: none;
      text-transform: uppercase;
      color: #ffffff;
      height: 45px;
      width: 50%;
      margin: 30px auto;

      &:hover {
        background: #252525;
      }
    }
  }
}
