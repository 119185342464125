.modal {
  position: fixed;
  z-index: 200;
  background-color: white;
  box-sizing: border-box;
  min-height: 353px;
  top: 17%;
  left: 30%;
  width: 40%;
  display: none;

  .modalTop {
    height: 55px;
    background: #252525;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 20px;

    .closeButton {
      width: auto;
      background: none;
      border: none;
      color: #fff;
      font-size: 26px;
      cursor: pointer;

      &:hover {
        color: #c0c0c0;
      }
    }
  }
}

.open {
  display: block;
}

.close {
  display: none;
}

@media screen and (max-width: 1140px) {
  .modal {
    left: 30%;
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .modal {
    width: 94%;
    left: 3%;

    .modalTop {
      font-size: 18px;
    }
  }
}
