.modalMain {
  padding: 30px;
  text-align: center;
  color: #575757;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .inputBlock {
      input {
        width: 100%;
        margin-bottom: 15px;
        outline: 0;
        padding: 10px 20px;
        border: none;
        border: 1px solid #bdbdbd;
      }
    }

    .textBlock {
      display: flex;
      text-transform: uppercase;
      text-align: left;
      margin: 0 0 15px;

      .logo {
        padding: 10px 15px 10px 20px;
      }

      .text {
        display: flex;
        align-items: center;
      }
    }

    .buttonBlock {
      .continueButton {
        background: #48b946;
        width: 60%;
        height: 45px;
        font-size: 16px;
        color: #ffffff;
        text-transform: uppercase;
        border: none;
        cursor: pointer;

        &:hover {
          background: #2e762d;
        }
      }
    }
  }
}

.open {
  display: block;
}

.close {
  display: none;
}

@media screen and (max-width: 568px) {
  .modalMain {
    form {
      .textBlock {
        font-size: 14px;
      }

      .buttonBlock {
        .continueButton {
          width: 100%;
        }
      }
    }
  }
}
