.mainContainer {
  position: absolute;
  display: flex;
  justify-content: space-around;
  top: 100%;
  width: 59%;
  left: 21%;
  z-index: 5;
  height: 70px;
  padding: 0 20px;
  align-items: center;
  box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  .searchInput {
    width: 85%;
    border: none;
    border-bottom: 1px solid rgb(187, 187, 187);
    padding: 0 10px;
    font-size: 24px;
    line-height: 29px;
    opacity: 1;
    outline: none;
    &::placeholder {
      opacity: 0.2;
    }
  }
}

@media screen and (max-width: 1024px) {
  .mainContainer {
    position: unset;
    width: 100%;
    padding: 0 30px;
    left: 0;
    align-items: center;
    justify-content: center;
    background: #f4f4f4;
    box-shadow: unset;
    .searchInput {
      padding: 0;
      width: 90%;
      margin-right: 15px;
      background: #f4f4f4;
      &::placeholder {
        opacity: 0.2;
      }
    }
    img {
      margin: 0;
    }
  }
}
