.wrapper {
  display: flex;
  height: 100vh;
  background: url('../../../assets/img/NotFoundCoverImg.png') no-repeat;
  background-position: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
  }
  .button {
    width: 220px;
    height: 45px;
    display: block;
    margin: 25px auto;
    font-size: 16px;
    background: #fff;
    color: #252525;
    border: 2px solid #252525;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    &:hover {
      background: #252525;
      border-color: #fff;
      color: #fff;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 320px) {
  .wrapper {
    background-size: contain;
    height: 100vw;
  }
}
