@font-face {
  font-family: 'MyriadPro-Regular';
  src: local('MyriadPro-Regular'), url(./fonts/MyriadPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'MyriadPro-Light';
  src: local('MyriadPro-Light'), url(./fonts/MyriadPro-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'MyriadPro-Black';
  src: local('MyriadPro-Black'), url(./fonts/MyriadPro-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'MyriadPro-Bold';
  src: local('MyriadPro-Bold'), url(./fonts/MyriadPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'MyriadPro-Semibold';
  src: local('MyriadPro-Semibold'), url(./fonts/MyriadPro-Semibold.ttf) format('truetype');
}

// Global styles
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  font-family: MyriadPro-Regular, sans-serif;
  font-style: normal;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

button {
  &:focus {
    outline: none;
  }
  &:active {
    color: none;
  }
}

// Common styles

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.sticky .stickyInner {
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.2);
}
.main-content {
  min-height: calc(100vh - 315px);
  @media screen and (max-width: 1024px) {
    min-height: calc(100vh - 370px);
  }
}

// .carouselArrowsStyles {
//   .carousel .control-next.control-arrow:before {
//     border-left: 8px solid rgb(88, 87, 87);
//   }
//   .carousel .control-prev.control-arrow:before {
//     border-right: 8px solid rgb(88, 87, 87);
//   }
//   .carousel .slide {
//     background-color: #fff;
//     max-height: 500px;
//   }
// }
.slick-dots {
  li {
    width: 8.5px;
    height: 8.5px;
    border-radius: 50%;
    border: 1px solid #c4c4c4;

    button::before {
      display: none;
    }
  }

  .slick-active {
    background: #252525;
    border-color: #252525;
  }
}

.slick-next {
  transform: translate(160%, 70%) rotate(180deg);
  position: absolute;
  right: 10px;
  top: 260px;

  &:hover {
    opacity: 0.4;
  }

  &:before {
    content: url('../assets/icons/arrow.svg');
  }
}

.slick-prev {
  transform: translate(-280%, 70%);
  position: absolute;
  left: 10px;
  top: 260px;

  &:hover {
    opacity: 0.4;
  }

  &:before {
    content: url('../assets/icons/arrow.svg');
  }
}
