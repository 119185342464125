.appHeader {
  //position: sticky;
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.2);
  top: 0;
  z-index: 19;
  min-height: 3rem;
}

.blackBlock {
  background-color: #252525;
  color: white;
}

.blackBlockContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.logoutBtn {
  width: auto;
  background-color: #252525;
  border: none;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .appHeader {
    background-color: #252525;
    min-height: 3rem;
    .logo {
      width: 80px;
      height: 32px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .dropDownMenu {
      background: white;
      .changeLangContainer {
        display: flex;
        justify-content: center;
        padding: 20px 80px;
        .languageButton {
          width: auto;
          height: inherit;
          padding: 7px;
          border-radius: 50%;
          margin: 5px;
          background: white;
          color: black;
          border: 2px solid black;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
        }
      }
      .menu {
        display: unset;
        flex-direction: column;
        // align-items: unset;
        background: white;
        width: unset;

        &:first-child {
          border-top: rgba(19, 19, 19, 0.2);
        }
        .menuItem_notDrop {
          display: flex;
          // flex-direction: column;
          width: 100%;
          justify-content: space-between;
        }
        .subMenu {
          position: relative;
          top: 0.5em;
          left: -0.5em;
          display: flex;
          flex-direction: column;
          text-overflow: wrap;
          background: white;
          width: 100%;
          padding: 20px;
          font-size: 16px;
          line-height: 19px;
          text-transform: uppercase;
          visibility: visible;
          justify-content: space-between;
          align-items: flex-start;
          box-shadow: none;

          a:not(:last-child) {
            margin-bottom: 23px;
          }
          a {
            text-decoration: none;
            color: #252525;
          }
        }
        .menuItem {
          display: flex;
          flex-direction: column;
          // align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgba(19, 19, 19, 0.2);
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          text-transform: uppercase;
          color: #131313;
          padding: 15px 50px;
          &:hover {
            text-decoration: none;
          }
          &:first-child {
            border-top: 1px solid rgba(19, 19, 19, 0.2);
          }
          img {
            height: 12px;
            width: 6px;
            transform: rotate(-90deg);
            // margin-left: 5px;
          }
        }
      }
    }
    .firstPart {
      box-sizing: border-box;
      min-height: 3rem;
      color: white;
      font-family: MyriadPro-Regular, sans-serif;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
    }
  }
  .basket {
    display: flex;
    justify-content: space-between;
    .icons {
      img {
        width: 19px;
        height: 24px;
        // b: #FFFFFF;
      }
    }
  }
  .logo {
    position: absolute;
    // top: 50%;
    right: 39%;
    // margin: 0 auto;
    // align-self: center;
  }

  .dropHeader {
    display: flex;
    flex-direction: column;
  }
}
.phone {
  display: flex;
  margin: 0 50px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  p {
    margin: 0 10px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.changeLanguageContainer {
  margin-right: 43px;
}

.languageButton {
  background-color: #252525;
  border: none;
  // border-radius: 50%;
  color: #fff;
  height: 32px;
  width: 32px;
  font-size: 12px;
  margin: 5px 0;
  &:hover {
    cursor: pointer;
    font-weight: bold;
    // background-color: #fff;
    // color: #000000;
  }
}

.logo {
  &:hover {
    cursor: pointer;
  }
}

.navbar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  display: flex;
  flex-grow: 1;
  // width: 60%;
  justify-content: space-around;
  // padding: 0 25px;
  list-style: none;
  li {
    a {
      text-decoration: none;
      color: #252525;
    }
    img {
      height: 8px;
      width: 4px;
      transform: rotate(-90deg);
      margin-left: 5px;
    }
  }

  // align-items: flex-start;
}
.menuItem {
  // position: relative;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #131313;
  .subMenu {
    position: absolute;
    display: flex;
    visibility: hidden; //none
    flex-direction: column;
    background: white;
    width: 260px;
    padding: 20px;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.2);

    a:not(:last-child) {
      margin-bottom: 15px;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  img {
    height: 8px;
    width: 4px;
    transform: rotate(-90deg);
    margin-left: 5px;
  }
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    .subMenu {
      visibility: visible;
      z-index: 10;
    }
  }
}

.cart {
  display: flex;
  .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .searchIconContainer {
      width: 31px;
      margin: 0 40px 0;
      padding: 2px 0 0 0;
    }
    .crossIcon {
      width: 24px;
      height: 24px;
      opacity: 0.7;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    .searchIcon {
      width: 31px;
      height: 31px;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .cartContainer {
    display: flex;

    span:first-child {
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
    }
    span:last-child {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
    .infoCart {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .cart {
      position: relative;
      margin-right: 10px;

      .counter {
        position: absolute;
        z-index: 99;
        top: 40%;
        left: 25%;
        font-weight: 700;
        font-size: 13px;
      }

      .cartLogo {
        width: 31px;
        height: 38px;
        margin-right: 10px;
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }

  .adminSettings {
    &:hover {
      opacity: 0.8;
    }
  }
}
.dropDownTitle {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0 auto;
}
