.footer {
  // display: flex;
  // flex-direction: column;
  background: #252525;
  .footer_main {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
  }
  a:hover {
    text-decoration: underline;
  }
  .rights {
    text-align: center;
    border-top: solid #6c6c6c;

    font-weight: 600;
    font-size: 12px;
    padding: 10px 0 12px;
    color: #6c6c6c;
  }
  .logo {
    align-self: center;
    div {
      font-weight: normal;
      font-size: 40px;
      line-height: 48px;
      color: #ffffff;
      &:hover {
        cursor: pointer;
      }
    }
    .socialNetworks {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      a {
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 32px;
          height: 32px;
        }
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.additional {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  a {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    color: #ffffff;
    font-family: MyriadPro-Light, sans-serif;
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  a {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 15px;

    color: #ffffff;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contacts {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 15px;
  color: #ffffff;
  align-self: center;
  .keys {
    font-family: MyriadPro-Light, sans-serif;
    font-weight: 300;
  }
  a {
    text-decoration: none;
    color: white;
  }
}

.openMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    transform: rotate(-90deg);
    color: #ffffff;
  }
}

.dropDownMenus {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 15px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;

  color: #ffffff;
  img {
    height: 8px;
    width: 8px;
  }
}
.menuList {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  background: #ffffff;
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .footer {
    box-sizing: border-box;
    flex-direction: column;
    padding: 0;
    min-height: 311px;
    height: auto;
  }
  .logo {
    // height: 54px;
    color: #ffffff;
    align-items: center;
    width: 100%;
    text-align: center;
    border-bottom: solid #6c6c6c;
    // top: 0;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    padding: 12px 0;
  }

  .rights {
    position: unset;
    top: 92%;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding: 10px;
  }

  .socialNetworks {
    position: unset;
    display: flex;
    justify-content: space-around;
    padding: 25px 50px;
    img {
      width: 40px;
      height: 40px;
    }
  }

  .navigation {
    a {
      color: #131313;
    }
  }
  .dropDownMenus {
    a {
      text-decoration: none;
      color: #ffffff;
      font-family: MyriadPro-Light, sans-serif;
    }
  }
  .additional {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: auto;
    width: 137px;
    a {
      color: #131313;
      margin-bottom: 25px;
      font-family: MyriadPro-Light, sans-serif;
      &:last-child {
        margin: 0;
      }
    }
  }
  .contacts {
    margin: 20px 15px;
  }
}
