.cartItem {
  display: flex;
  position: relative;
  margin: 1.5em 0 1.5em;
  font-size: 20px;

  .itemImage {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    height: 30vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .containerInfoPart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 4;
    margin-left: 1.875em;
    border-bottom: 1px solid #dbdbdb;
    .infoPart {
      width: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .itemName {
        flex: 3;
        display: flex;
        justify-content: flex-start;
        text-transform: uppercase;
      }

      .itemCount {
        display: flex;
        justify-content: center;
        align-items: center;

        flex: 1.2;

        button {
          width: 30px;
          height: 30px;
          box-sizing: border-box;
          cursor: pointer;
          background-color: inherit;
          border: none;
        }
        button:hover {
          background: #b5b3b3;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          width: 30px;
          height: 30px;
          border: 1px solid #dcdcdc;
          box-sizing: border-box;
          box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
        }
      }

      .itemPrice {
        flex: 1;
        text-align: right;

        span {
          font-weight: 600;
        }
      }
    }

    .wrapperSizes {
      display: flex;
      align-items: flex-end;
      margin-bottom: 8px;
      label {
        font-family: Myriad Pro;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #888888;
        margin-right: 10px;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 30px;
        height: 30px;
        border: 1px solid #dcdcdc;
        padding: 0 5px;
        font-family: Myriad Pro;
        font-size: 14px;
      }
    }
  }

  .deleteButton {
    position: absolute;
    right: 0px;
    width: 15px;
    height: 15px;
    background-image: url('~assets/icons/delete.png');
    background-color: #ffffff;
    background-repeat: no-repeat;
    cursor: pointer;
    border: none;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .cartItem {
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 0.5em;
    display: grid;
    grid-template-columns: 135px 1fr;
    grid-row-gap: 20px;

    .itemImage {
      flex: 4;
    }

    .containerInfoPart {
      flex-direction: column;
      border-bottom: none;
      margin-left: 0.5em;
      justify-content: flex-start;

      .infoPart {
        flex-grow: 0;
        height: 55%;
        .itemName {
          flex: 1.5;
          justify-content: flex-start;
          width: 100%;
        }
        .itemCount {
          flex: 1;

          justify-content: flex-start;
          width: 100%;
          order: 3;

          button {
            width: 40px;
            height: 40px;
          }

          span {
            width: 40px;
            height: 40px;
          }
        }
        .itemPrice {
          flex: 1;
          text-align: left;

          width: 100%;
          order: 2;
        }
      }
    }
    .mobileCount {
      grid-column: span 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      button {
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        cursor: pointer;
        background-color: inherit;
        border: none;
      }
      .itemCount {
        display: flex;
        span {
          display: block;
          text-align: center;
          line-height: 30px;
          font-size: 16px;
          width: 30px;
          height: 30px;
          border: 1px solid #dcdcdc;
          box-sizing: border-box;
          box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
