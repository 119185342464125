.modal {
  position: fixed;
  background-color: white;
  box-sizing: border-box;
  top: 10%;
  left: 35%;
  width: 30%;
  display: none;
  height: max-content;
  z-index: 100;

  .closeButton {
    width: auto;
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    color: #c0c0c0;
    font-size: 38px;
    cursor: pointer;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  }

  .modalContent {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}

.open {
  display: block;
}

.close {
  display: none;
}

@media screen and (max-width: 1140px) {
  .modal {
    left: 30%;
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .modal {
    width: 94%;
    left: 3%;

    .modalTop {
      font-size: 18px;
    }
  }
}
