.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    &:hover {
      opacity: 0.6;
    }
  }
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-right: 10px;
  }
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  button {
    background-color: #252525;
    border: none;
    color: #fff;
  }
}

@media screen and (min-width: 320px) and (max-width: 420px) {
  .auth {
    display: flex;
    // margin-left: 10em;
    // justify-content: center;
    // align-items: center;
  }
}
