.modal {
  position: fixed;
  z-index: 200;
  background-color: white;
  box-sizing: border-box;
  height: 330px;
  top: 17%;
  left: 30%;
  width: 40%;
  display: none;

  .modalTop {
    height: 55px;
    background: #252525;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 20px;

    .closeButton {
      width: auto;
      background: none;
      border: none;
      color: #fff;
      font-size: 26px;
      width: auto;
      cursor: pointer;

      &:hover {
        color: #c0c0c0;
      }
    }
  }

  .modalMain {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 290px;
    padding: 30px;
    text-align: center;
    font-size: 24px;
    color: #575757;

    .modalConfirmIcon {
      width: 70px;
      height: 70px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }

    .modalText {
      line-height: 1.5;
    }

    .buttonBlock {
      width: 100%;
      display: flex;
      justify-content: space-between;

      a {
        width: 48%;
      }

      button {
        width: 48%;
        height: 45px;
        font-size: 16px;
        color: #ffffff;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
      }

      .continueButton {
        background: #505050;

        &:hover {
          background: #252525;
        }
      }

      .cartButton {
        background: #48b946;
        width: 100%;

        &:hover {
          background: #2e762d;
        }
      }
    }
  }
}

.open {
  display: block;
}

.close {
  display: none;
}

@media screen and (max-width: 1024px) {
  .modal {
    left: 25%;
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .modal {
    .modalMain {
      padding: 20px;

      .buttonBlock {
        flex-direction: column;

        a {
          width: 100%;
        }

        button {
          width: 100%;
        }

        .continueButton {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .modal {
    width: 94%;
    left: 3%;

    .modalTop {
      font-size: 18px;
    }

    .modalMain {
      font-size: 20px;
    }
  }
}
