centralization {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyCartMessage {
  @extend centralization;
  margin-top: 5rem;
}

.cart {
  padding-top: 3.75em;
  padding-bottom: 6.25em;
  outline: none;
}

.bottomNavigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 300;
  font-size: 26px;
  color: #131313;
  border: none;

  button {
    @extend centralization;
    min-width: 250px;
    display: flex;
    font-size: 16px;
    color: #ffffff;
    text-transform: uppercase;
    padding: 1em 2em 1em;
    border: none;
    margin: 0.6em 2em 0;
    cursor: pointer;
  }
  .buttonReturn {
    background: #505050;

    &:hover {
      background: #252525;
    }
  }
  .buttonBuy {
    background: #48b946;

    &:hover {
      background: #2e762d;
    }
  }

  span {
    font-weight: 700;
    background-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .cart {
    padding-top: 2.25em;
    padding-bottom: 4.25em;

    h1 {
      text-align: center;
    }

    .bottomNavigation {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 0;

      div {
        margin-top: 10px;
      }
      button {
        display: flex;
        font-size: 16px;
        color: #ffffff;
        text-transform: uppercase;
        border: none;
        width: 100%;
        cursor: pointer;
      }
      .buttonBuy {
        margin: 0.8em 0 1.6em;
      }
    }
  }
}
