.buttonGoUp {
  position: fixed;
  bottom: 50px;
  right: 0;
  background: none;
  border: none;

  .imgGoUp {
    &:hover {
      background: #252525;
      cursor: pointer;
    }
  }
}
